import "./App.css";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    console.log(window.SwaggerUI);
  }, []);
  return (
    <div className="App">
      <SwaggerUI url="swagger.json" layout="BaseLayout" />
    </div>
  );
}

export default App;
